import request from '@/framework/utils/request'

export function loginByUsername(userInfo) {
  const data = {
    ...userInfo,
    grant_type: 'password'
  }
  return request({
    url: '/c/login/oauth/token',
    method: 'post',
    params: data,
    header: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    auth: {
      username: 'frontend',
      password: 'ZnJvbnRlbmQyMDIwQFFBWg=='
    }
  })
}

/**
 * 获取用于加密密码的公钥
 * @returns 
 */
export function getPublicKey() {
  return request({
    url: '/c/login/api/crypto/publicKey',
    method: 'get'
  })
}
/**
 * 外网登录
 * @param {*} params 
 * @returns 
 */
export function outsideLogin(params) {
  const data = {
    ...params
  }
  return request({
    url: '/c/login/oauth/token',
    method: 'post',
    params: data,
    header: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    auth: {
      username: 'frontend',
      password: 'ZnJvbnRlbmQyMDIwQFFBWg=='
    }
  })
}

export function logout() {
  return request({
    url: '/c/login/logout',
    method: 'post'
  })
}

export function getUserInfo() {
  return request({
    url: '/c/admin/api/sysUser/noPermission/userInfo',
    method: 'get'
  })
}

export function getValidateCodeImg() {
  const url = '/c/login/api/validateCode'
  return request.get(url).then(res => {
    return Promise.resolve(res)
  })
}

export function refreshValidateCodeImg() {
  const url = '/c/login/api/validateCode/refresh'
  return request.get(url).then(res => {
    return Promise.resolve(res)
  })
}

